import { Controller } from "@hotwired/stimulus"

// Handles the showing and hiding of the new flash messages UI.
export default class extends Controller {
  static values = {
    hiddenClasses: { type: String, default: "opacity-0 translate-y-4 scale-90 pointer-events-none" },
    visibleClasses: { type: String, default: "opacity-100 translate-y-0 scale-100" },
    duration: { type: Number, default: 5000 }
  }

  connect() {
    this.hidden = this.hiddenClassesValue.split(" ");
    this.visible = this.visibleClassesValue.split(" ");
    this.duration = this.durationValue;

    // The show delay is necessary to trigger the animation.
    setTimeout(() => {
      this.show();
    }, 1)

    setTimeout(() => {
      this.hide();
    }, this.duration)
  }

  show() {
    this.element.classList.add(...this.visible);
    this.element.classList.remove(...this.hidden);
  }

  hide() {
    this.element.classList.remove(...this.visible);
    this.element.classList.add(...this.hidden);
  }
}