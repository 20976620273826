import { Controller } from "@hotwired/stimulus"

const DEBOUNCE_MS = 100;

// Takes it's target div and extends it down to the bottom of the viewport. Useful for preventing scrolling of the page.
export default class extends Controller {
  static values = { bottomMargin: Number, default: 0 }

  connect() {
    window.addEventListener('resize', this.onWindowResize.bind(this));
    this.setMaxHeight();
  }

  onWindowResize() {
    clearTimeout(this.debounced);
    this.debounced = setTimeout(() => {
      this.setMaxHeight();
    }, DEBOUNCE_MS);
  }

  setMaxHeight() {
    let trueOffsetTop = this.element.getBoundingClientRect().top + window.scrollY;
    let remainingHeight = window.innerHeight - trueOffsetTop - this.bottomMarginValue;
    this.element.style.height = remainingHeight + "px";
  }
}