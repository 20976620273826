import { hideElement, showElement } from 'scripts/utils/visibility';
import { setupAlertMenu } from '../../components/alert_form';

document.addEventListener('turbolinks:load', () => {
  const newDisruptionModal = document.getElementById('new-disruption-modal');
  if(!newDisruptionModal) return;

  setupAlertMenu('new-disruption-modal');
  initializeCreateEventAlertTemplateSelect(newDisruptionModal);
  setupAlertModalListenners(newDisruptionModal);
  setupEventFormListenner(newDisruptionModal);
});

const setupAlertModalListenners = (container) => {
  // Toggle Alert form inputs based on checkbox status
  const sendAlertCheckbox = container.querySelector('#disruption-send-alert');
  sendAlertCheckbox.addEventListener('click', (e) => { toggleAlertCheckboxStyle(container, e) });

  // Toggle checkbox color style based on alert priority
  const standardEventPriorityContainer = container.querySelector('#alert-standard-priority-container');
  const emergencyEventPriorityContainer = container.querySelector('#alert-emergency-priority-container');
  standardEventPriorityContainer.addEventListener('click', () => {
    changeElementPrimaryColor(sendAlertCheckbox, 'rose', 'indigo');
  });
  emergencyEventPriorityContainer.addEventListener('click', () => {
    changeElementPrimaryColor(sendAlertCheckbox, 'indigo', 'rose');
  });
}

const setupEventFormListenner = (container) => {
  const eventCategorySelector = container.querySelector('[data-selector="event-category"]');
  const eventTypeOptions = [...container.querySelector('[data-selector="event-type"]').options];
  eventCategorySelector.addEventListener('change', (e) => { updateEventTypeOptions(eventTypeOptions, e) });
  // Trigger the change event so the types are updated based on the first available catedory
  eventCategorySelector.dispatchEvent(new Event('change'));
  // Update the number of affected population based on selected location
  const disruptionLocationSelector = container.querySelector('[data-selector="disruption-location"]');
  const affectedPersonnelCounterContainer = container.querySelector('#affected-personnel-counter-container');
  disruptionLocationSelector.addEventListener('change', (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    affectedPersonnelCounterContainer.innerHTML = selectedOption.dataset.populationSize;
  });
  // Initialize affected personnel value with the first location's population count
  disruptionLocationSelector.dispatchEvent(new Event('change'));
}

const changeElementPrimaryColor = (element, oldColorKey, newColorKey) => {
  element.className = element.className.replaceAll(oldColorKey, newColorKey);
}

const updateEventTypeOptions = (eventTypeOptions, e) => {
  const selectedCategory = e.target.options[e.target.selectedIndex].value;
  const eventTypeSelector = document.querySelector('[data-selector="event-type"]');
  eventTypeSelector.innerHTML = '';
  eventTypeOptions.forEach(option => {
    if(option.dataset.category == selectedCategory) eventTypeSelector.add(option);
  });
  eventTypeSelector.selectedIndex = 0;
}

const toggleAlertCheckboxStyle = (container, e) => {
  const sendAlertCheckbox = e.target;
  const alertForm = container.querySelector('#alert-form');
  const icon = container.querySelector('#alert-icon-modal');

  if (sendAlertCheckbox.checked) {
    showElement(alertForm);
  } else {
    changeElementPrimaryColor(icon, 'red', 'gray');
    hideElement(alertForm);
  }
}

const initializeCreateEventAlertTemplateSelect = (container) => {
  const eventContainer = container.querySelector('#disruptive-event-container');
  const titleInput = container.querySelector('#disruption_disruptive_event_title');
  const descriptionInput = eventContainer.querySelector('div.markdown');
  const categorySelect = container.querySelector('#disruption_disruptive_event_category');
  const eventTypeSelect = container.querySelector('#disruption_disruptive_event_event_type');
  const createEventAlertTemplateSelect = container.querySelector('#alert_template_id');

  if (titleInput && descriptionInput && categorySelect && eventTypeSelect && createEventAlertTemplateSelect) {
    const updateDataset = (element, datasetKey, value) => {
      element.dataset[datasetKey] = value;
    };

    // Set current category and event type to the alert template select
    createEventAlertTemplateSelect.dataset.eventCategory = categorySelect.value;

    // NOTE: For some reason eventTypeSelect.value is not returning the first option value
    // for the selected initial category "Disasters". I'm using the querySelector to get the value instead.
    const eventType = eventTypeSelect.querySelector('option[data-category="disasters"]').value
    createEventAlertTemplateSelect.dataset.eventType = eventType;

    titleInput.addEventListener('input', function() {
      updateDataset(createEventAlertTemplateSelect, 'eventTitle', titleInput.value);
    });

    descriptionInput.addEventListener('input', function() {
      updateDataset(createEventAlertTemplateSelect, 'eventDescription', descriptionInput.innerHTML);
    });

    categorySelect.addEventListener('change', function() {
      updateDataset(createEventAlertTemplateSelect, 'eventCategory', categorySelect.value);
    });

    eventTypeSelect.addEventListener('change', function() {
      updateDataset(createEventAlertTemplateSelect, 'eventType', eventTypeSelect.value);
    });
  } else {
    console.error('One or more elements not found:', {
      titleInput,
      descriptionInput,
      categorySelect,
      eventTypeSelect,
      createEventAlertTemplateSelect
    });
  }
};
