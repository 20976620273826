import { selectCard, unselectCard } from '../utils/alerts';

const setupAlertMenu = (componentId = 'incident-alert-modal') => {
  const alertModal = document.getElementById(componentId);
  if (alertModal) setupQuickOptions(alertModal);
}

const setupAlertTemplateMenu = (componentId = 'alert-template-form-container') => {
  const alertTemplateContainer = document.getElementById(componentId);
  if (!alertTemplateContainer) return;

  setupQuickOptions(alertTemplateContainer);
}

const setupQuickOptions = (container) => {
  const elements = {
    // Priority elements
    standardPriorityContainer: container.querySelector('#alert-standard-priority-container'),
    emergencyPriorityContainer: container.querySelector('#alert-emergency-priority-container'),
    standardPriorityContainerIndicator: container.querySelector('#alert-standard-priority-indicator'),
    emergencyPriorityContainerIndicator: container.querySelector('#alert-emergency-priority-indicator'),
    alertPriorityDescription: container.querySelector('#alert-priority-description'),
    emergencyPriorityRadioButton: container.querySelector('#alert_emergency_true'),
    standardPriorityRadioButton: container.querySelector('#alert_emergency_false'),
    // Recipient elements
    likelyPersonnelContainer: container.querySelector('#alert-likely-personnel-container'),
    allPersonnelContainer: container.querySelector('#alert-all-personnel-container'),
    likelyPersonnelContainerIndicator: container.querySelector('#alert-likely-personnel-indicator'),
    allPersonnelContainerIndicator: container.querySelector('#alert-all-personnel-indicator'),
    alertChannelsDescription: container.querySelector('#alert-channels-description'),
    allPersonnelRadioButton: container.querySelector('#alert_all_personnel_true'),
    likelyPersonnelRadioButton: container.querySelector('#alert_all_personnel_false'),
    // Other elements
    containerIcon: container.querySelector('#alert-icon-modal'),
    submitButton: container.querySelector('#submit-alert-button'),
    alertForm: container.querySelector('#alert-form'),
    // Locations
    selectAllLocationsButton: container.querySelector('#select-all-locations-button'),
    unselectAllLocationsButton: container.querySelector('#unselect-all-locations-button')
  };

  const isEmergencyPriority = () => elements.emergencyPriorityRadioButton ? elements.emergencyPriorityRadioButton.checked : null;
  const sendingToAllPersonnel = () => elements.allPersonnelRadioButton ? elements.allPersonnelRadioButton.checked : null; 

  if (!elements.emergencyPriorityRadioButton || !elements.emergencyPriorityContainer) return;
  if (!elements.standardPriorityRadioButton || !elements.standardPriorityContainer) return;

  const setInitialState = (emergency = null, allPersonnel = null) => {
    const isEmergency = emergency ?? isEmergencyPriority();
    const isAllPersonnel = allPersonnel ?? sendingToAllPersonnel();

    if (isEmergency) {
      handlePriorityContainerClick(
        elements.emergencyPriorityContainer,
        elements.standardPriorityContainer,
        elements.emergencyPriorityContainerIndicator,
        elements.standardPriorityContainerIndicator,
        elements.emergencyPriorityRadioButton,
        elements.standardPriorityRadioButton,
        true
      );
    } else {
      handlePriorityContainerClick(
        elements.standardPriorityContainer,
        elements.emergencyPriorityContainer,
        elements.standardPriorityContainerIndicator,
        elements.emergencyPriorityContainerIndicator,
        elements.standardPriorityRadioButton,
        elements.emergencyPriorityRadioButton,
        false
      );
    }

    if (elements.allPersonnelContainer && elements.allPersonnelRadioButton) { // Do not try to set the personnel card elements are not present.
      if (isAllPersonnel) {
        handlePersonnelContainerClick(
          elements.allPersonnelContainer,
          elements.likelyPersonnelContainer,
          elements.allPersonnelContainerIndicator,
          elements.likelyPersonnelContainerIndicator,
          elements.allPersonnelRadioButton,
          elements.likelyPersonnelRadioButton
        );
      } else {
        handlePersonnelContainerClick(
          elements.likelyPersonnelContainer,
          elements.allPersonnelContainer,
          elements.likelyPersonnelContainerIndicator,
          elements.allPersonnelContainerIndicator,
          elements.likelyPersonnelRadioButton,
          elements.allPersonnelRadioButton
        );
      }
    };
  };

  const handlePriorityContainerClick = (selectedContainer, unselectedContainer, selectedIndicator, unselectedIndicator, selectedRadioButton, unselectedRadioButton, isEmergency) => {
    selectedRadioButton.checked = true;
    unselectedRadioButton.checked = false;
    unselectCard(unselectedContainer, unselectedIndicator);
    selectCard(selectedContainer, selectedIndicator, isEmergency);

    // Update UI elements based on the selected priority
    toggleRecipientsCards([elements.likelyPersonnelContainer, elements.allPersonnelContainer], isEmergency);
    toggleRecipientsIndicators([elements.likelyPersonnelContainerIndicator, elements.allPersonnelContainerIndicator], isEmergency);
    toggleAlertIconColor(elements.containerIcon, isEmergency);
    toggleAlertPriorityDescription(elements.alertPriorityDescription, isEmergency);
    toggleAlertChannelsDescription(elements.alertChannelsDescription, isEmergency);
    toggleSubmitAlertButton(elements.submitButton, isEmergency);
    toggleSelectAllButtons(elements.selectAllLocationsButton, elements.unselectAllLocationsButton, isEmergency);
  };

  const handlePersonnelContainerClick = (selectedContainer, unselectedContainer, selectedIndicator, unselectedIndicator, selectedRadioButton, unselectedRadioButton) => {
    const isEmergency = isEmergencyPriority(); // Check if the selected priority is an emergency alert

    selectedRadioButton.checked = true;
    unselectedRadioButton.checked = false;
    unselectCard(unselectedContainer, unselectedIndicator);
    selectCard(selectedContainer, selectedIndicator, isEmergency);
  };

  const fetchTemplateContent = async (container, templateId, eventAttributes = {}) => {
    const params = new URLSearchParams({
      id: templateId,
      event_id: eventAttributes.eventId || '',
      event_title: eventAttributes.eventTitle || '',
      event_description: eventAttributes.eventDescription || '',
      event_category: eventAttributes.eventCategory || '',
      event_type: eventAttributes.eventType || ''
    });
    const url = `/alerts/alert_template_content?${params.toString()}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
  
      // Set Alert Title
      container.querySelector('#alert_name').value = data.title;
  
      // Set Alert Content
      // Not sure why but the content markdown has two elements, a div and a textarea.
      // I'm updating both to be safe.
      const markdowns = container.querySelectorAll('.markdown');
      markdowns.forEach((markdown) => {
        markdown.innerHTML = data.content;
        if (markdown.hasAttribute('data-placeholder')) {
          markdown.removeAttribute('data-placeholder');
        }
      });

      // Set Alert Priority and Recipients
      setInitialState(data.emergency, data.all_personnel);

    } catch (error) {
      console.error('Error fetching template content:', error);
    }
  }

  const setupAlertTemplateOptions = () => {
    const select = container.querySelector('#alert_template_id');
  
    select.addEventListener('change', (event) => {
      const templateName = container.querySelector('#alert_template_name');
      const templateId = event.target.value;
  
      // Updates hidden field used for log tracking
      templateName.value = event.target.options[event.target.selectedIndex].text;

      const eventAttributes = {
        eventId: event.target.dataset.eventId,
        eventTitle: event.target.dataset.eventTitle,
        eventDescription: event.target.dataset.eventDescription,
        eventCategory: event.target.dataset.eventCategory,
        eventType: event.target.dataset.eventType
      };

      fetchTemplateContent(elements.alertForm, templateId, eventAttributes);
    });
  }
  elements.standardPriorityContainer.addEventListener('click', () => {
    handlePriorityContainerClick(
      elements.standardPriorityContainer,
      elements.emergencyPriorityContainer,
      elements.standardPriorityContainerIndicator,
      elements.emergencyPriorityContainerIndicator,
      elements.standardPriorityRadioButton,
      elements.emergencyPriorityRadioButton,
      false);
  });
  elements.emergencyPriorityContainer.addEventListener('click', () => {
    handlePriorityContainerClick(
      elements.emergencyPriorityContainer,
      elements.standardPriorityContainer,
      elements.emergencyPriorityContainerIndicator,
      elements.standardPriorityContainerIndicator,
      elements.emergencyPriorityRadioButton,
      elements.standardPriorityRadioButton,
      true);
  });

  // On some alerts, the personnel selection is not present
  if (elements.likelyPersonnelContainer && elements.allPersonnelContainer) {
    elements.likelyPersonnelContainer.addEventListener('click', () => {
      handlePersonnelContainerClick(
        elements.likelyPersonnelContainer,
        elements.allPersonnelContainer,
        elements.likelyPersonnelContainerIndicator,
        elements.allPersonnelContainerIndicator,
        elements.likelyPersonnelRadioButton,
        elements.allPersonnelRadioButton);
    });
    elements.allPersonnelContainer.addEventListener('click', () => {
      handlePersonnelContainerClick(
        elements.allPersonnelContainer,
        elements.likelyPersonnelContainer,
        elements.allPersonnelContainerIndicator,
        elements.likelyPersonnelContainerIndicator,
        elements.allPersonnelRadioButton,
        elements.likelyPersonnelRadioButton);
    });
  }

  setInitialState();
  if (elements.alertForm) setupAlertTemplateOptions();
}

const toggleRecipientsCards = (cards, isEmergency) => {
  if (!cards || cards.length === 0 || cards.every(card => card === null)) return;

  const colors = {
    true: 'indigo-700',
    false: 'red-600'
  };

  const targetColor = colors[isEmergency];
  const newColor = colors[!isEmergency];

  cards.forEach((card) => {
    if (card.classList.contains(`border-${targetColor}`)) {
      card.classList.replace(`border-${targetColor}`, `border-${newColor}`);
    }
  });
};

const toggleRecipientsIndicators = (indicators, isEmergency) => {
  if (!indicators || indicators.length === 0 || indicators.every(indicator => indicator === null)) return;

  const colors = {
    true: 'indigo-700',
    false: 'red-600'
  };

  const targetColor = colors[isEmergency];
  const newColor = colors[!isEmergency];

  indicators.forEach((indicator) => {
    if (indicator.classList.contains(`text-${targetColor}`)) {
      indicator.classList.replace(`text-${targetColor}`, `text-${newColor}`);
    }
  });
};

const toggleAlertIconColor = (icon, isEmergency) => {
  if (!icon) return;

  icon.classList.toggle('text-red-600', isEmergency);
  icon.classList.toggle('text-gray-700', !isEmergency);
};

const toggleAlertPriorityDescription = (description, isEmergency) => {
  if (!description) return;

  const message = isEmergency
    ? 'Recipients receive the alert on all available communication channels.'
    : 'Recipients receive the alert on their preferred communication channel.';

  description.textContent = message;
};

const toggleAlertChannelsDescription = (description, isEmergency) => {
  if (!description) return;

  const message = isEmergency
    ? 'This alert will immediately be sent to recipients via all available communication channels including: SMS, Email, Push Notification.'
    : 'This alert will immediately be sent to affected personnel via their preferred communication channel.';

  description.textContent = message;
};

const toggleSubmitAlertButton = (button, isEmergency) => {
  if (!button) return;

  if (isEmergency) {
    button.value = button.dataset.emergencyText;
    button.classList.replace('bg-indigo-600', 'bg-red-600');
    button.classList.add('bg-red-600');
    button.classList.replace('hover:bg-indigo-700', 'hover:bg-red-700');
  } else {
    button.value = button.dataset.notEmergencyText;
    button.classList.replace('bg-red-600', 'bg-indigo-600');
    button.classList.replace('hover:bg-red-700', 'hover:bg-indigo-700');
  }
};

const toggleSelectAllButtons = (selectAllButton, unselectAllButton, isEmergency) => {
  if (!selectAllButton || !unselectAllButton) return;

  if (isEmergency) {
    selectAllButton.className = selectAllButton.className.replaceAll('indigo', 'rose');
    unselectAllButton.className = unselectAllButton.className.replaceAll('indigo', 'rose');
  } else {
    selectAllButton.className = selectAllButton.className.replaceAll('rose', 'indigo');
    unselectAllButton.className = unselectAllButton.className.replaceAll('rose', 'indigo');
  }
};

export { setupAlertMenu, setupAlertTemplateMenu };
