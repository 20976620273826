import { hideActiveModal } from '../../utils/modals';

document.addEventListener('turbolinks:load', initializeTeamMembers);

function initializeTeamMembers() {
  const searchForm = document.querySelector('#personSearch');
  const addTeamMemberBtn = document.querySelector('#add-team-member');
  const teamMemberInfo = document.querySelector('#team-member-info');
  const teamMembersTable = document.querySelector('#team-members-table');

  if (!searchForm || !addTeamMemberBtn || !teamMemberInfo || !teamMembersTable) return;

  const tableBody = teamMembersTable.querySelector('tbody');
  const teamMemberIdsField = document.querySelector('#team-member-ids');

  function handleSearchResultClick(e) {
    if (e.target.tagName === 'LI') {
      teamMemberInfo.dataset.selectedMemberId = e.target.dataset.personId;
      teamMemberInfo.dataset.selectedMemberFirstName = e.target.dataset.firstName;
      teamMemberInfo.dataset.selectedMemberLastName = e.target.dataset.lastName;
      teamMemberInfo.dataset.selectedMemberFullName = `${e.target.dataset.firstName} ${e.target.dataset.lastName}`;
      teamMemberInfo.dataset.selectedMemberRoleName = e.target.dataset.personRoleName;
      teamMemberInfo.dataset.selectedMemberPrimaryGroupName = e.target.dataset.personPrimaryLocationName || '-';
      teamMemberInfo.dataset.selectedMemberPrimaryLocationName = e.target.dataset.personPrimaryLocationName || '-';
    }
  }

  function handleAddTeamMemberClick(_e) {
    hideActiveModal();

    const row = document.createElement('tr');
    row.classList.add('text-gray-900', 'even:bg-white', 'odd:bg-gray-50');
    row.dataset.personId = teamMemberInfo.dataset.selectedMemberId;

    row.innerHTML = `
      <td class="px-3 py-2 text-sm leading-5 flex items-center">${teamMemberInfo.dataset.selectedMemberFullName}</td>
      <td class="px-3 py-2 text-sm leading-5">${teamMemberInfo.dataset.selectedMemberRoleName}</td>
      <td class="px-3 py-2 text-sm leading-5">${teamMemberInfo.dataset.selectedMemberPrimaryGroupName}</td>
      <td class="px-3 py-2 text-sm leading-5">${teamMemberInfo.dataset.selectedMemberPrimaryLocationName}</td>
      <td class="px-3 py-2 text-sm leading-5 text-rose-600 hover:text-rose-700">
        <a href="#" class="remove-link flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="100%" height="100%" class="h-4 w-4 inline mr-1">
            <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm3 10.5a.75.75 0 000-1.5H9a.75.75 0 000 1.5h6z" clip-rule="evenodd"></path>
          </svg>
          Remove
        </a>
      </td>
    `;

    // Remove the empty row if it exists
    const emptyRow = tableBody.querySelector('#empty-team-members');
    if (emptyRow) emptyRow.remove();

    if (addTeamMemberToHiddenField(teamMemberInfo.dataset.selectedMemberId)) {
      tableBody.appendChild(row);
    }
  }

  function handleRemoveLinkClick(event) {
    if (event.target.classList.contains('remove-link')) {
      event.preventDefault();
      const rowToRemove = event.target.closest('tr');
      
      if (rowToRemove) {
        removeTeamMemberFromHiddenField(rowToRemove.dataset.personId);
        rowToRemove.remove();
      }

      if (tableBody.children.length === 0) {
        // Append the empty row to the table body
        const emptyRow = document.createElement('tr');
        emptyRow.id = 'empty-team-members';
        emptyRow.classList.add('text-gray-900');
        emptyRow.innerHTML = `
          <td class="px-3 py-2 text-sm leading-5 text-gray-400" colspan="5">
            <div class="flex justify-center items-center h-full">
              No team members added.
            </div>
          </td>
        `;
        tableBody.appendChild(emptyRow);
      }
    }
  }

  function addTeamMemberToHiddenField(personId) {
    const teamMemberIds = teamMemberIdsField.value ? JSON.parse(teamMemberIdsField.value) : [];
    const numericPersonId = Number(personId);

    if (!teamMemberIds.includes(numericPersonId)) {
      teamMemberIds.push(numericPersonId);    
      teamMemberIdsField.value = JSON.stringify(teamMemberIds.map(Number));
      return true; // ID was added
    }

    return false; // ID was not added
  }

  function removeTeamMemberFromHiddenField(personId) {
    const teamMemberIds = JSON.parse(teamMemberIdsField.value);
    const index = teamMemberIds.indexOf(Number(personId));

    if (index > -1) {
      teamMemberIds.splice(index, 1);
      teamMemberIdsField.value = JSON.stringify(teamMemberIds.map(Number));
    }
  }

  searchForm.querySelector('#searchResults').addEventListener('click', handleSearchResultClick);
  addTeamMemberBtn.addEventListener('click', handleAddTeamMemberClick);
  tableBody.addEventListener('click', handleRemoveLinkClick);
};
