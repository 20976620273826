import { Controller } from "@hotwired/stimulus"

// Connect to data-controller="tabs"
export default class extends Controller {
  static targets = ["tab", "content", "externalContent"]

  connect() {
    this.ensureFirstTabSelected();
    this.showContentForSelectedTab();
  }

  ensureFirstTabSelected() {
    const selectedTab = this.tabTargets.find((tab) => this.isActiveTab(tab));
    if (!selectedTab && this.tabTargets.length > 0) {
      this.activateTab(this.tabTargets[0]);
    }
  }

  showContentForSelectedTab() {
    // Hide all content and external content elements
    this.contentTargets.forEach((content) => {
      content.style.display = "none";
    });

    this.externalContentTargets.forEach((content) => {
      content.style.display = "none";
    });

    // Show content for the selected tab
    const selectedTab = this.tabTargets.find((tab) => this.isActiveTab(tab));
    if (selectedTab) {
      const contentId = selectedTab.dataset.contentId;
      document.getElementById(contentId).style.display = "block";

      const externalContent = this.externalContentTargets.find((link) => link.dataset.contentId === contentId);
      if (externalContent) {
        externalContent.style.display = "inline";
      }
    }
  }

  selectTab(event) {
    event.preventDefault();

    // Deactivate all tabs
    this.tabTargets.forEach((tab) => {
      this.deactivateTab(tab);
    });

    // Activate the selected tab
    const selectedTab = event.currentTarget;
    this.activateTab(selectedTab);

    // Show content for the selected tab
    this.showContentForSelectedTab();
  }

  isActiveTab(tab) {
    return this.activeTabClasses.every((cls) => tab.classList.contains(cls));
  }

  activateTab(tab) {
    tab.classList.add(...this.activeTabClasses);
    tab.classList.remove(...this.inactiveTabClasses);
  }

  deactivateTab(tab) {
    tab.classList.remove(...this.activeTabClasses);
    tab.classList.add(...this.inactiveTabClasses);
  }

  get activeTabClasses() {
    return this._activeTabClasses || (this._activeTabClasses = this.activeTabClass.split(" "));
  }

  get inactiveTabClasses() {
    return this._inactiveTabClasses || (this._inactiveTabClasses = this.inactiveTabClass.split(" "));
  }

  get activeTabClass() {
    return this.data.get("activeTabClass") || "text-indigo-600 border-indigo-500";
  }

  get inactiveTabClass() {
    return this.data.get("inactiveTabClass") || "text-gray-500 border-transparent";
  }
}
