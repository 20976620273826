import { Controller } from "@hotwired/stimulus"
import Ably from "ably";
import Rails from "@rails/ujs";

// Connects to data-controller="cases-messages"
// It's responsible for handling scrolling the message history window
// and adding new messages as they come in. Loading prior history.
/**
 * Long note to follow...
 *
 * I had set about expecting to have this stimulus controller completely handle all of the message history.
 * However, Stimulus does not work with Rails UJS and it's async form handling. I.e., you can't just fire up an
 * ajax request within this controller and have it evaluate as javascript the response back from the server. Meaning
 * that you can't use the the js view to append rendered partials. That's pretty fundamental and not worth giving up.
 */
export default class extends Controller {
  static targets = [
    'text', 'submit', 'scroller'
  ]
  static values = { channelName: String, url: String, newest: String, oldest: String, author: String }

  connect() {
    this.scrollToBottom();
    this.setupRealtime();
    /**
     * Here's the thing. The event handler for keyboard with stimulus:
     * <textarea data-action='keydown.ctrl+enter->case-messages#keyboardSubmit()' ...>
     * does not fire! Further, the code in cases.js that handles disabling the message send button on submit really
     * shouldn't be over there. It should be in here. But I haven't figured out how to add that to the form as a stimulus
     * action. It's handled automatically in Turbo. But we don't use turbo...
     */
    this.textTarget.form.addEventListener('submit', (event) => {
      this.submitTarget.setAttribute('disabled', true);
    });

    this.textTarget.addEventListener('keydown', (event) => {
      if (!((event.metaKey || event.ctrlKey || event.keyCode == 91 || event.keyCode == 93) && (event.keyCode == 13))) return
      Rails.fire(this.textTarget.form, 'submit');
    });
  }

  disconnect() {
    this.client.close();
  }

  scrollToBottom() {
    this.scrollerTarget.scrollTop = this.scrollerTarget.scrollHeight;
  }

  setupRealtime() {
    // console.log("Setting up realtime channel for: " + this.channelNameValue);
    if(this.channelNameValue) {
      this.client = new Ably.Realtime({
        authUrl: "/auth/realtime/token"
      });
      this.client.connection.on(function (event) {
        // console.log("Connection state changed: " + event.current);
        // console.log(event);
      });
      let channel = this.client.channels.get(this.channelNameValue);
      // console.log("Listening on: " + this.channelNameValue);
      channel.subscribe("newMessage", (message) => {
        // console.log("Received message...");
        // console.log(message);
        if(message['data']['author_uid'] !== this.authorValue) {
          // If we did not post this message, let's go grab it.
          // console.log("Not our message, reloading timeline.")
          Rails.fire(document.querySelector("#later-messages-form"), 'submit');
        }
      });
    } else {
      console.log("No channel name!");
    }
  }
}