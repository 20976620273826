import { hideElement, showElement } from 'scripts/utils/visibility';
import { selectCard, unselectCard } from 'scripts/utils/alerts.js';
import { setupAlertMenu } from '../../components/alert_form';

document.addEventListener('turbolinks:load', () => {
  const alertModal = document.getElementById('alert-modal');
  if(!alertModal) return;

  setupAlertMenu('alert-modal');

  // Form Checkboxes (Cards)
  const standardAlertPriorityContainer = document.getElementById('alert-standard-priority-container');
  const standardAlertPriorityContainerIndicator = document.getElementById('alert-standard-priority-indicator');
  const emergencyAlertPriorityContainer = document.getElementById('alert-emergency-priority-container');
  const emergencyAlertPriorityContainerIndicator = document.getElementById('alert-emergency-priority-indicator');
  // Locations checkboxes
  const locationCheckboxes = document.getElementsByClassName('alert-location-checkbox');
  // Select / unselect all locations buttons
  const selectAllLocationsButton = document.getElementById('select-all-locations-button');
  const unselectAllLocationsButton = document.getElementById('unselect-all-locations-button');
  // Submission Buttons
  const standardAlertSubmitButton = document.getElementById('standard-alert-submit-button');
  const emergencyAlertSubmitButton = document.getElementById('emergency-alert-submit-button');

  selectAllLocationsButton.addEventListener('click', () => {
    locationCheckboxes.forEach((checkbox) => {
      checkbox.checked = true;
    });
  });

  unselectAllLocationsButton.addEventListener('click', () => {
    locationCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  });

  standardAlertPriorityContainer.addEventListener('click', () => {
    hideElement(emergencyAlertSubmitButton);
    showElement(standardAlertSubmitButton);
    showElement(standardAlertPriorityContainerIndicator);
    unselectCard(emergencyAlertPriorityContainer, emergencyAlertPriorityContainerIndicator);
    selectCard(standardAlertPriorityContainer, standardAlertPriorityContainerIndicator, false);
    // Update selected location checkboxes
    locationCheckboxes.forEach((checkbox) => {
      changeElementPrimaryColor(checkbox, 'rose', 'indigo')
    });
  });

  emergencyAlertPriorityContainer.addEventListener('click', () => {
    hideElement(standardAlertSubmitButton);
    showElement(emergencyAlertSubmitButton);
    showElement(emergencyAlertPriorityContainerIndicator);
    unselectCard(standardAlertPriorityContainer, standardAlertPriorityContainerIndicator);
    selectCard(emergencyAlertPriorityContainer, emergencyAlertPriorityContainerIndicator, true);
    // Update selected location checkboxes
    locationCheckboxes.forEach((checkbox) => {
      changeElementPrimaryColor(checkbox, 'indigo', 'rose')
    });
  });
});

const changeElementPrimaryColor = (element, oldColorKey, newColorKey) => {
  element.className = element.className.replaceAll(oldColorKey, newColorKey);
}
