
export default function setupMediumEditor() {
  const editableTextAreas = document.querySelectorAll('textarea.medium-editor');

  if (editableTextAreas.length === 0) return;

  editableTextAreas.forEach((textarea) => {
    if (!textarea.hasAttribute('data-medium-editor-initialized')) {
      new MediumEditor(textarea, {
        toolbar: {
          buttons: ['bold', 'italic', 'underline', 'anchor', 'h1', 'h2', 'h3'],
        },
        anchor: {
          linkValidation: true,
        }
      });
      textarea.setAttribute('data-medium-editor-initialized', 'true');
    }
  });
}

document.addEventListener('turbolinks:load', () => {
  setupMediumEditor();
});
