import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

// Connects to data-controller="filter"
// It's responsible for handling the hiding and showing of the filter dialog
// And decorating any "list" options when active.
// Find an example in: app/views/incidents/cases/_filter_form.html.haml
export default class extends Controller {
  static targets = [
    'dialog',
  ]

  connect() {
    useClickOutside(this)
  }

  show() {
    this.dialogTarget.classList.remove("opacity-0", "-translate-y-2", "pointer-events-none");
    this.dialogTarget.classList.add("opacity-100");
  }

  hide() {
    this.dialogTarget.classList.remove("opacity-100");
    this.dialogTarget.classList.add("opacity-0", "-translate-y-2", "pointer-events-none");
  }

  clickOutside(event) {
    this.hide()
  }

  submitSearch() {
    this.element.submit();
  }

  clearSearch() {
    // find the q input and set it's value to empty.
    let searchInput = this.element.querySelector("[name='q']");
    searchInput.value = "";
    this.element.submit();
  }

  toggle(event) {
    let checkbox = event.target;
    // TODO: This isn't great right now. But it's tough on UI to have a common ancestor between both <label> and <select>
    // Will come back and fix this within the PR.
    let parent = checkbox.closest("[data-filter-row]")
    let rowIndex = parent.dataset["filter-row"];

    this.dialogTarget.querySelectorAll("[data-filter-row='" + rowIndex + "']").forEach((parent) => {
      parent.querySelectorAll("label").forEach((label) => {
        if(checkbox.checked) {
          label.classList.replace("text-gray-500", "text-gray-900");
        } else {
          label.classList.replace("text-gray-900", "text-gray-500");
        }
      });
      parent.querySelectorAll("select").forEach((select) => {
        if(checkbox.checked) {
          select.classList.replace("border-gray-300", "border-gray-400")
        } else {
          select.classList.replace("border-gray-400", "border-gray-300")
        }
      });
    });
  }
}